<template>
    <LiefengContent>
        <template v-slot:title>
            答题记录
            <span class="eye" @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
        </template>
        <template v-slot:toolsbarRight>
            <Cascader
                transfer
                @on-change="changeCasder"
                :load-data="loadData"
                change-on-select
                style="margin-right: 10px; width: 300px"
                :data="CascaderList"
                v-model="CascaderId"
                placeholder="请选择区/街道/社区"
            ></Cascader>
            <Input :maxlength="20" v-model.trim="userName" placeholder="按姓名搜索" style="width: 150px; margin-right: 10px" />
            <span style="line-height: 32px; width: 70px">成功状态：</span>
            <Select v-model="validStatus" style="width: 150px; margin-right: 10px">
                <Option v-for="item in validStatusList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
            <Button type="primary" @click="search" icon="ios-search">搜索</Button>
            <Button type="info" style="color: #fff" @click="reset" icon="ios-refresh">重置</Button>
            <Button @click="exportExcel" type="error" icon="ios-cloud-download-outline">导出</Button>
            <Button type="success" @click="clickBack">返回</Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :total="total"
                :curPage="currentPage"
                @hadlePageSize="pageChange"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :pageSize="pageSize"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/answerrecord')
import LiefengContent from "@/components/LiefengContent"
import LiefengTable from "@/components/LiefengTable"
import { format } from "@/utils/formatTime"
export default {
    data() {
        return {
            // 脱敏
            hasSensitive: true,
            //成功状态
            validStatus: "0",
            validStatusList: [
                {
                    value: "0",
                    label: "全部",
                },
                {
                    value: "1",
                    label: "有效",
                },
                {
                    value: "2",
                    label: "无效",
                },
            ],
            loading: false,
            //搜索框内容
            userName: "",
            //表头列表
            talbeColumns: [
                {
                    title: "街道",
                    key: "streetName",
                    align: "center",
                    width:140
                },
                {
                    title: "社区",
                    key: "communityName",
                    align: "center",
                    width:140
                },
                {
                    title: "姓名",
                    key: "userName",
                    width: 140,
                    align: "center",
                },
                {
                    title: "年龄",
                    key: "age",
                    width: 140,
                    align: "center",
                },
                {
                    title: "性别",
                    key: "sex",
                    width: 140,
                    align: "center",
                },
                {
                    title: "联系电话",
                    key: "mobile",
                    width: 140,
                    align: "center",
                },
                {
                    title: "答题时间",
                    key: "endTime",
                    width: 200,
                    align: "center",
                },
                {
                    title: "通过状态",
                    key: "validStatus",
                    width: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("span", params.row.validStatus == "1" ? "成功" : "失败")
                    },
                },
                
                // {
                //   title: "排行",
                //   key: "sort",
                //   width: 100,
                //   align: "center",
                // },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            CascaderList: [],
            CascaderId: [],
            isNum: 0,
        }
    },
    methods: {
        clickBack(){
            var index = parent.layer.getFrameIndex(window.name) 
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push('/newanswerindex?menuId=' + this.$route.query.menuId)
            }
            
        },
        // 获取级联
        getCascader() {
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: "4401",
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    this.CascaderList = this.forMatterTree(res.dataList)
                    this.CascaderList.map(item => {
                        item.loading = false
                    })
                    // if (this.isNum == 0 && this.CascaderList.length != 0) {
                    //     this.CascaderId.push(this.CascaderList[0].value)
                    // }
                    var arr = []
                     if(this.$route.query.type == '1'){
                         arr = [
                            {
                                title: "用时",
                                key: "timeTotal",
                                width: 150,
                                align: "center",
                                render: (h, params) => {
                                    let time = params.row.timeTotal
                                    let h1 = Math.floor(time / 3600)
                                    let m = Math.floor((time / 60) % 60)
                                    let s = Math.floor(time % 60)
                                    return h("span", h1 + "时" + m + "分" + s + "秒")
                                },
                            },   
                         ]
                     }else if(this.$route.query.type == '2'){
                        // 计分
                        arr = [
                            {
                                title: "分数",
                                key: "score",
                                minWidth: 140,
                                align: "center",
                            },   
                            {
                                title: "用时",
                                key: "timeTotal",
                                width: 150,
                                align: "center",
                                render: (h, params) => {
                                    let time = params.row.timeTotal
                                    let h1 = Math.floor(time / 3600)
                                    let m = Math.floor((time / 60) % 60)
                                    let s = Math.floor(time % 60)
                                    return h("span", h1 + "时" + m + "分" + s + "秒")
                                },
                            },
                         ]
                    }else if(this.$route.query.type == '3'){
                        // 考试
                        arr = [
                             {
                                title: "分数",
                                key: "score",
                                minWidth: 140,
                                align: "center",
                            },    
                         ]
                    }
                    this.talbeColumns = [...this.talbeColumns,...arr]
                    // this.hadlePageSize({
                    //     pageSize: this.pageSize,
                    //     page: this.pageNum,
                    // })
                }
            })
        },
        changeCasder(val, item) {
            if (item.length == 1) {
                // this.$Message.warning({
                //   content:'请选择到社区进行查询',
                //   background:true
                // })
                // return
            } else if (item.length == 2) {
                //  this.$Message.warning({
                //     content:'请选择到社区进行查询',
                //     background:true
                //   })
                //   return
            } else if (item.length == 3) {
                this.orgCode = item[2].orgCode
                this.orgName = item[2].orgName
                console.log(this.orgName)
            }
        },
        forMatterTree(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].label = data[i].orgName
                data[i].value = data[i].orgCode
                if (data[i].children && data[i].children != null && data[i].children.length > 0) {
                    this.forMatterTree(data[i].children)
                }
            }
            return data
        },
        loadData(item, callback) {
            item.loading = true
            let params = {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: item.orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }
            this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                ...params,
            }).then(res => {
                if (res.code == 200 && res.dataList.length != 0) {
                    let arr = this.forMatterTree(res.dataList)
                    arr.map(items => {
                        if (items.orgLevel == 4) {
                            items.loading = false
                        }
                    })
                    item.loading = false
                    item.children = arr
                    callback(item.children)
                    // if (this.isNum == 0 && item.children.length != 0) {
                    //     this.CascaderId.push(item.children[0].value)
                    //     this.isNum = 1
                    //     return
                    // } else if (this.isNum == 1 && item.children.length != 0) {
                    //     this.CascaderId.push(item.children[0].value)
                    //     this.isNum++
                    //     this.orgCode = item.children[0].value
                    //     // console.log(item.children[0])
                    //     console.log(item.children[0].orgName)

                    //     this.orgName = item.children[0].orgName
                    //     this.orogCode = item.children[0].value
                    //     console.log(this.editRow)
                    //     this.pageChange({
                    //         page: 1,
                    //         pageSize: this.pageSize,
                    //     })
                    // }
                } else {
                    callback([])
                }
            })
        },
        //导出excel
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
                // onOk: () => {
                //   let tHeader = [
                //     "街道",
                //     "社区",
                //     "姓名",
                //     "联系电话",
                //     "答题时间",
                //     "通过状态",
                //     "用时",
                //   ];
                //   let filterVal = [
                //     "streetName",
                //     "communityName",
                //     "userName",
                //     "mobile",
                //     "endTime",
                //     "validStatus",
                //     "timeTotal",
                //   ];
                //   this.$core.exportExcel(
                //     tHeader,
                //     filterVal,
                //     this.tableData,
                //     "答题记录列表"
                //   );
                // },
                onOk: () => {
                    this.$get(
                        "/voteapi/api/pc/componentQuestion/exportAnswerUserPage",
                        {
                            // custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            // shareStatus: 2,
                            // templateId: id,
                            // templateName: name,
                            userName: this.userName,
                            businessType: "0",
                            businessCode: this.$route.query.id,
                            validStatus: this.validStatus != "0" ? this.validStatus : "",
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Modal.remove()
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }

                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                            this.templateStatus = false
                        } else {
                            this.$Modal.remove()
                            this.$Message.error({
                                content: "导出失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        //搜索
        search() {
            this.isNum++
            if (this.CascaderId.length >= 1 && this.CascaderId.length != 3) {
                this.$Message.warning({
                    content: "请选择到社区再进行查询",
                    background: true,
                })
                return
            }
            this.pageChange({
                page: 1,
                pageSize: this.pageSize,
            })
        },
        //重置
        reset() {
            this.userName = ""
            this.currentPage = 1
            this.pageSize = 20
            this.validStatus = "0"
            this.CascaderId = []
            this.pageChange({
                page: this.currentPage,
                pageSize: this.pageSize,
            })
        },
        //修改res数据的时间格式
        dataFilter(res) {
            res.dataList.map((item, index, arr) => {
                arr[index].idNumType = item.idNumType == "1" ? "中国身份证" : item.idNumType == "2" ? "港澳台身份证" : item.idNumType == "3" ? "护照" : "其他"
                arr[index].sex = item.sex == "1" ? "男" : "女"

                let startTime = new Date(item["voteTime"])
                let startYear = startTime.getFullYear()
                let startMonth = startTime.getMonth() + 1 < 10 ? "0" + (startTime.getMonth() + 1) : startTime.getMonth() + 1
                let startDay = startTime.getDate() < 10 ? "0" + startTime.getDate() : startTime.getDate()
                let startHorus = startTime.getHours() < 10 ? "0" + startTime.getHours() : startTime.getHours()
                let startMinutes = startTime.getMinutes() < 10 ? "0" + startTime.getMinutes() : startTime.getMinutes()
                let startSeconds = startTime.getSeconds() < 10 ? "0" + startTime.getSeconds() : startTime.getSeconds()
                arr[index].voteTime = startYear + "-" + startMonth + "-" + startDay + " " + startHorus + ":" + startMinutes + ":" + startSeconds
            })
            this.tableData = res.dataList
        },
        pageChange(obj) {
            this.loading = true
            this.$get("/voteapi/api/pc/componentQuestion/selectAnswerUserPage", {
                userName: this.userName,
                businessType: "0",
                businessCode: this.$route.query.id,
                page: obj.page,
                pageSize: obj.pageSize,
                validStatus: this.validStatus != "0" ? this.validStatus : "",
                hasSensitive: this.hasSensitive,
                // orgCode: '' || parent.vue.loginInfo.userinfo.orgCode,
                orgCode: this.CascaderId[this.CascaderId.length - 1],
            })
                .then(res => {
                    if (res.code == 200) {
                        res.dataList.map((item, index) => {
                            if (item.endTime) {
                                item.endTime = format(item.endTime, "yyyy-MM-dd HH:mm:ss")
                            }
                        })
                        this.tableData = res.dataList
                        this.currentPage = res.currentPage
                        this.pageSize = res.pageSize
                        this.total = res.maxCount
                        this.loading = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "获取数据失败",
                        })
                        this.loading = false
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        changeEye() {
            this.hasSensitive = !this.hasSensitive
            this.pageChange({
                page: this.currentPage,
                pageSize: this.pageSize,
            })
        },
    },
    created() {
        this.getCascader()
        this.pageChange({
          page: 1,
          pageSize: 20,
        });
    },
    components: {
        LiefengContent,
        LiefengTable,
    },
}
</script>
    
<style scoped lang='less'>
.eye {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    margin: 0 15px 0;
}
</style>